.bg-baner {

	.banner-item {

		&__content {
		    bottom:50%;

		    p {
		    	width: 70%;
		    	margin: 0;
		    }

		    .main-heading {
		    	width:100%;
		    	padding:0 10%;
			}

			.content-arrow {
				padding:0 10%;
			}

			.bottom-array {
				text-align: center;
				display:block;

				p {
					margin: 0 auto;
					font-size: 14px;
					width:100%;
					padding-top: 20%;
				}

				a {
					color: $white;
					font-size: 18px;
				}
			}

		}

	}

}

.arrow-tab {
		display: block;
}

.arrow-des {
	display: none;
}

.carousel--banner {
	min-height: 60rem;
}
