

// .header {
// 	background-color: $white;
// }

.product-detail-page {
	margin-top: 8.3rem;
	// border-top: 1px solid $border-top;

	.product-slider {
		margin-bottom: 0;
		top: 5rem;
		.detail-center {
			height: 50rem;

		}

		.dot-link {
			left: 2rem;
			top: 10rem;
			a {
				display: block;
				width: 7rem;
			}
		}
	}

	.list-product-detail__items {

		.center {

				p {
					// margin-top: 2rem;
					// height: 8rem;
					// margin: 0 auto;
					max-width: 100%;
				}
			}
	}

	.products-item {
		padding: 0 3.5rem;
	}

	.detail-right {
		padding-left: 0;

		ul {
			padding-left: 0;

			li {
				margin-bottom: 0;
			}
		}


		button {
			padding: 1.5rem 4rem;
		}
	}



	.health {

		.news {
			padding: 0 1.5rem;

			.news-detail {

				.video-news {
					height: 20rem;

					&:before {
						height: 20rem;
					}
				}


				img {
					height: 20rem;
				}

			}
		}



	}
}
