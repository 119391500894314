// Block item

.connect {

	&--change {
		max-width: none;
		// padding: 0 8.5rem;
	}

	&__send-mail {
		padding: 0 8.5rem;

		.main-title--1, > p {
			text-align: center;
		}
	}

	&__address, &__form {
		padding: 0 7rem;
	}

	&__form {
		.contact-form {
			margin-top: 4rem;
		}
	}

	.g-recaptcha {
		max-width: 61.5rem;
	}
}

.wrapper-content {
	max-width: 117rem;
	// padding-right: $grid-gutter-width/2;
	// padding-left: $grid-gutter-width/2;
}

.connect__form .button--black {
	float: left;
}
