.sidebar-right {
	padding-top: 8rem;
}

.faq, .recruit {

	.button--black {
		width: auto;
		// margin-bottom: 0;
	}
}

.faq-content, .recruit-content {
	opacity: 1;
	transition: opacity 0.3s ease;
	padding-left: $grid-gutter-width/2;
	padding-right: $grid-gutter-width/2;

	&.hidden {
		opacity: 0;
		display: none;
	}


	.block-position-detail {
		margin-bottom: 2rem;

		.panel-heading {
			position: relative;
			border-right: 1px solid $grey-light;

			a {
				// border-right: 1px solid $border-contact;
				line-height: 1.5;
			}
		}
	}
}
