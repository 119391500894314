/**
 * Style for phablets & tablets
 */
@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.3333333333%;
  }
  .col-sm-push-2 {
    left: 16.6666666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.3333333333%;
  }
  .col-sm-push-5 {
    left: 41.6666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.3333333333%;
  }
  .col-sm-push-8 {
    left: 66.6666666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.3333333333%;
  }
  .col-sm-push-11 {
    left: 91.6666666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .section-cover__content {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .section-cover__content .button--trans {
    width: auto;
    margin: 0 auto;
  }
  .bg-cover-other-pr--recruit-thumb {
    padding-bottom: 0%;
  }
  .bg-cover-other-pr--banner-top-static {
    padding-bottom: 20%;
  }
  .my-container-tablet {
    width: 75rem;
    margin: 0 auto;
  }
  .header {
    height: 8rem;
  }
  .header__inner {
    height: 8rem;
    padding-left: 2.5rem;
  }
  .header--light .header__background {
    height: 8rem;
  }
  .navbar-toggle {
    height: 8rem;
    font-size: 3rem;
    right: 2.5rem;
  }
  .mennu-toggle {
    height: 8rem;
    font-size: 3rem;
    right: 2.5rem;
  }
  .logo img {
    width: 11rem;
  }
  .header__content {
    padding-left: 0;
    margin-left: 0;
  }
  .nav-mobile .nav-mobile-inner {
    right: 0;
    left: inherit;
    width: 40rem;
  }
  .nav-mobile .close {
    right: 40rem;
    left: inherit;
  }
  .nav-mobile .has-dropdown .expand-dropdown {
    right: 0rem;
  }
  .home-item--1 .thumb, .home-item--1 .desc {
    float: right;
  }
  .home-item--2 {
    height: 100vh;
  }
  .home-item--2__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;
  }
  .home-item--3 {
    height: 80rem;
    background-image: radial-gradient(circle, #fff, #efefef);
  }
  .home-item--3 .bgkem-item-3 {
    position: absolute;
    margin: 0;
    top: 60%;
    left: 35%;
    transform: translate(-50%, -50%);
    width: 45rem;
    max-width: none;
    padding-top: 45rem;
  }
  .home-item--3 .bgkem-item-3__shadow {
    position: absolute;
    top: 97%;
    left: 32%;
    width: 35rem;
    transform: translate(-50%, -50%);
  }
  .home-item--3__container {
    height: 100%;
    position: relative;
    max-width: 992px;
    margin: 0 auto;
  }
  .homepage .video-information {
    display: block;
    overflow: hidden;
    padding: 7rem 0;
  }
  .homepage .history-us__content .thumb {
    float: right;
  }
  .homepage .history-us__header2 {
    padding-top: 7rem;
    padding-bottom: 3rem;
  }
  .homepage .history-us__image {
    padding-bottom: 30%;
  }
  .homepage .history-us__text {
    padding-top: 0;
  }
  .cover-block-video {
    margin-top: 0;
    height: 60vh;
  }
  .connect__send-mail .send-mail {
    max-width: 615px;
    margin: 0 auto;
  }
  .connect__send-mail .send-mail .form-group {
    width: 70%;
    float: left;
    text-align: left;
  }
  .connect__send-mail .send-mail .button {
    width: 30%;
    float: right;
  }
  .connect__send-mail .send-mail .button:hover, .connect__send-mail .send-mail .button:focus {
    box-shadow: none;
  }
  .follow__social {
    text-align: left;
  }
  .follow .main-title--1 {
    text-align: left;
  }
  .connect__form .main-title--1 {
    text-align: left;
  }
  .list-logo-branch {
    margin: 3rem 0;
  }
  .list-logo-branch ul li {
    width: 25%;
  }
  .list-logo-branch ul li a {
    width: auto;
    text-align: left;
  }
  .list-logo-branch ul li a img {
    max-width: 100%;
  }
  .time-list-xs--fixed {
    top: 8rem;
  }
  .time-list-xs li {
    font-size: 1.2rem;
    width: 11%;
  }
  .time-list-xs li:last-child {
    width: 16%;
  }
  .list-tooltip-item .size {
    top: 47%;
    left: 57%;
    transform: translate(-50%, -50%);
  }
  .list-tooltip-item .material {
    top: 60%;
    left: 52%;
    transform: translate(-50%, -50%);
  }
  .list-tooltip-item .reliability {
    top: 65%;
    left: 36%;
    transform: translate(-50%, -50%);
  }
  .list-tooltip-item .designs {
    top: 80%;
    left: 44%;
    transform: translate(-50%, -50%);
  }
  .list-tooltip-item__description {
    position: absolute;
    top: 37%;
    left: 64%;
    transform: translate(-50%, -50%);
  }
  .list-tooltip-item__image {
    position: absolute;
    top: 13%;
    left: 12%;
    transform: translate(-50%, -50%);
  }
  .list-tooltip-item .tooltip-item__image-container {
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    top: 18rem;
    left: 18rem;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .list-tooltip-item .tooltip-item__image-container img {
    margin: 0 auto;
    margin-top: -7rem;
    display: block;
  }
  .list-tooltip-item .tooltip-item__image-container img.active {
    margin-top: 0;
  }
  .list-tooltip-item .tooltip-item__image-container.active {
    top: 0;
    left: 0;
    width: 36rem;
    height: 36rem;
  }
  .list-tooltip-item .tooltip-item__description {
    position: absolute;
    width: 25rem;
    line-height: 2;
  }
  .list-tooltip-item .tooltip-item__description__title {
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    visibility: hidden;
    opacity: 0;
    top: -2rem;
  }
  .list-tooltip-item .tooltip-item__description__content {
    position: relative;
    visibility: hidden;
    opacity: 0;
    bottom: -2rem;
  }
  .list-tooltip-item .tooltip-item__description.active .tooltip-item__description__title {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
  .list-tooltip-item .tooltip-item__description.active .tooltip-item__description__content {
    visibility: visible;
    opacity: 1;
    bottom: 0;
  }
  .footer p {
    font-size: 1.4rem;
  }
  .footer .container {
    display: table;
  }
  .footer .container [class^="col"] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
  .footer__left {
    text-align: left;
    display: inline-block;
  }
  .footer__image {
    display: inline-block;
    margin-left: 2rem;
  }
  .footer__image iframe {
    width: 5rem !important;
    height: 4.3rem !important;
    margin: 0;
  }
  .footer__right {
    text-align: right;
  }
  .bg-baner .banner-item__content {
    bottom: 50%;
  }
  .bg-baner .banner-item__content p {
    width: 70%;
    margin: 0;
  }
  .bg-baner .banner-item__content .main-heading {
    width: 100%;
    padding: 0 10%;
  }
  .bg-baner .banner-item__content .content-arrow {
    padding: 0 10%;
  }
  .bg-baner .banner-item__content .bottom-array {
    text-align: center;
    display: block;
  }
  .bg-baner .banner-item__content .bottom-array p {
    margin: 0 auto;
    font-size: 14px;
    width: 100%;
    padding-top: 20%;
  }
  .bg-baner .banner-item__content .bottom-array a {
    color: #fff;
    font-size: 18px;
  }
  .arrow-tab {
    display: block;
  }
  .arrow-des {
    display: none;
  }
  .carousel--banner {
    min-height: 60rem;
  }
  .trademark-omi-page .introduce, .trademark-obeauty-page .introduce, .trademark-nghia-page .introduce, .trademark-export-page .introduce {
    padding: 8rem 8.5rem;
  }
  .trademark-omi-page .introduce__content .introduce-text, .trademark-obeauty-page .introduce__content .introduce-text, .trademark-nghia-page .introduce__content .introduce-text, .trademark-export-page .introduce__content .introduce-text {
    padding-right: 2.5rem;
    line-height: 2;
  }
  .trademark-omi-page .introduce__content .introduce-text > p, .trademark-obeauty-page .introduce__content .introduce-text > p, .trademark-nghia-page .introduce__content .introduce-text > p, .trademark-export-page .introduce__content .introduce-text > p {
    color: #4a4a4a;
  }
  .trademark-omi-page .products, .trademark-obeauty-page .products, .trademark-nghia-page .products, .trademark-export-page .products {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 8rem;
  }
  .trademark-omi-page .export-item .thumb.sharpen-imports, .trademark-obeauty-page .export-item .thumb.sharpen-imports, .trademark-nghia-page .export-item .thumb.sharpen-imports, .trademark-export-page .export-item .thumb.sharpen-imports {
    padding-bottom: 72%;
  }
  .trademark-omi-page .export-item--1 .desc, .trademark-obeauty-page .export-item--1 .desc, .trademark-nghia-page .export-item--1 .desc, .trademark-export-page .export-item--1 .desc {
    padding: 8rem 8.5rem;
  }
  .trademark-omi-page .tabs--trademark .export-desc, .trademark-obeauty-page .tabs--trademark .export-desc, .trademark-nghia-page .tabs--trademark .export-desc, .trademark-export-page .tabs--trademark .export-desc {
    text-align: center;
    padding: 8rem 13.5rem;
  }
  .trademark-omi-page .tabs--trademark .export-desc .wrapper-img, .trademark-obeauty-page .tabs--trademark .export-desc .wrapper-img, .trademark-nghia-page .tabs--trademark .export-desc .wrapper-img, .trademark-export-page .tabs--trademark .export-desc .wrapper-img {
    display: inline-block;
    padding-bottom: 2rem;
  }
  .trademark-omi-page .export-comment .comment-customer, .trademark-obeauty-page .export-comment .comment-customer, .trademark-nghia-page .export-comment .comment-customer, .trademark-export-page .export-comment .comment-customer {
    padding: 8rem 4rem;
    height: 50rem;
  }
  .trademark-omi-page .tabs-item--1 .desc p, .trademark-obeauty-page .tabs-item--1 .desc p, .trademark-nghia-page .tabs-item--1 .desc p, .trademark-export-page .tabs-item--1 .desc p {
    height: 9rem;
  }
  .trademark-omi-page .sm, .trademark-obeauty-page .sm, .trademark-nghia-page .sm, .trademark-export-page .sm {
    display: none;
  }
  .trademark-omi-page .tl, .trademark-obeauty-page .tl, .trademark-nghia-page .tl, .trademark-export-page .tl {
    display: inline-block;
  }
  .trademark-omi-page .dk, .trademark-obeauty-page .dk, .trademark-nghia-page .dk, .trademark-export-page .dk {
    display: none;
  }
  .tabs--trademark .export-desc p {
    text-align: center;
  }
  .trademark-obeauty-page .tabs-item--1 .thumb {
    height: 20rem;
    width: auto;
  }
  .product-detail-page {
    margin-top: 8.3rem;
  }
  .product-detail-page .product-slider {
    margin-bottom: 0;
    top: 5rem;
  }
  .product-detail-page .product-slider .detail-center {
    height: 50rem;
  }
  .product-detail-page .product-slider .dot-link {
    left: 2rem;
    top: 10rem;
  }
  .product-detail-page .product-slider .dot-link a {
    display: block;
    width: 7rem;
  }
  .product-detail-page .list-product-detail__items .center p {
    max-width: 100%;
  }
  .product-detail-page .products-item {
    padding: 0 3.5rem;
  }
  .product-detail-page .detail-right {
    padding-left: 0;
  }
  .product-detail-page .detail-right ul {
    padding-left: 0;
  }
  .product-detail-page .detail-right ul li {
    margin-bottom: 0;
  }
  .product-detail-page .detail-right button {
    padding: 1.5rem 4rem;
  }
  .product-detail-page .health .news {
    padding: 0 1.5rem;
  }
  .product-detail-page .health .news .news-detail .video-news {
    height: 20rem;
  }
  .product-detail-page .health .news .news-detail .video-news:before {
    height: 20rem;
  }
  .product-detail-page .health .news .news-detail img {
    height: 20rem;
  }
  .product-landing-page .main {
    padding: 0 1.5rem;
  }
  .product-landing-page .product-type-list {
    padding-top: 8rem;
  }
  .product-landing-page .product-type {
    border: none;
  }
  .product-landing-page .product-type__title {
    font-size: 4.4rem;
  }
  .product-pages-header {
    top: 7.7rem;
    width: auto;
  }
  .product-pages-header__top {
    width: 18rem;
    border: 1px solid #f6f6f6;
    padding-top: 0;
    left: 1px;
  }
  .product-pages-header__top > ul {
    border-right: 1px solid #f6f6f6;
    width: 18rem;
  }
  .product-pages-header__top a {
    border: none;
  }
  .product-pages-header__top li > a {
    padding: 0;
  }
  .product-pages-header__top li > a::before {
    display: none;
  }
  .product-pages-header__left {
    border-right: none;
    width: 18rem;
  }
  .product-pages-header__left__filter-menu {
    font-size: 5rem;
  }
  .product-pages-header__left__filter-menu .icon--view {
    font-size: 3rem;
  }
  .product-list-page .wrapper-content {
    position: relative;
  }
  .product-list-page .main {
    padding: 0 1.5rem;
  }
  .product-list-page .product-list-wrapper {
    padding-top: 8rem;
    margin-bottom: 5.5rem;
    margin-left: 15rem;
    overflow: hidden;
  }
  .product-list-page .product-list {
    text-align: justify;
    margin-left: 4rem;
    margin-top: 5rem;
  }
  .product-list-page .product {
    vertical-align: bottom;
    border: none;
    display: inline-block;
    margin-bottom: 5rem;
  }
  .product-list-page .product__thumb {
    height: 20rem;
    display: block;
    overflow: hidden;
  }
  .product-list-page .product__thumb img {
    width: 100%;
  }
  .product-list-page .product__serial {
    font-size: 1.6rem;
    height: 5rem;
  }
  .product-list-page .product__serial > span {
    display: block;
  }
  .product-list-page .product__title {
    font-size: 1.6rem;
  }
  .product-list-page__footer {
    background-color: #fff;
    height: 5.5rem;
    border-top: 1px solid #ddd;
  }
  .product-list-page__footer a {
    line-height: 3.5rem;
    padding: 0 1rem;
    width: 14rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
  .product-list-page .product-list-filter-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 55;
  }
  .product-list-page .product-list-filter {
    position: absolute;
    top: 8rem;
    left: 18rem;
    width: 32.5rem;
    padding: 1rem 1.5rem 1.5rem;
  }
  .product-list-page .product-list-filter__header {
    font-size: 4.4rem;
  }
  .product-list-page .product-list-filter__child {
    padding: 1.5rem 0;
  }
  .product-list-page .product-list-filter__footer {
    padding: 4rem 1.5rem;
    bottom: 8.1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product-list-page .product:nth-child(2n+1) {
    clear: left;
  }
}

@media (min-width: 768px) {
  .one-news-item {
    padding-top: 4rem;
  }
  .one-news-item:first-child {
    padding-top: 5rem;
  }
  .one-news-item__publish-time {
    position: absolute;
    left: -8rem;
    width: 7rem;
    margin-top: 0;
  }
  .one-news-item__publish-time__day {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #ebebeb;
    padding: 1.5rem;
    margin: 0;
  }
  .one-news-item__publish-time__day li {
    font-size: 2rem;
    margin: 0;
    font-weight: 600;
    line-height: 1;
  }
  .one-news-item__publish-time__day li:first-child {
    font-weight: 700;
    font-size: 2.6rem;
  }
  .one-news-item__publish-time__icon {
    line-height: 1;
    padding-top: 1.5rem;
    padding-left: 0;
    font-size: 3rem;
    text-align: center;
    margin: 0 2rem;
  }
  .one-news-item__content {
    position: relative;
    float: right;
  }
  .one-news-item__content__short {
    margin-bottom: 4rem;
  }
  .one-news-item__content__go-out {
    margin-bottom: 4rem;
  }
  .one-news-item__content__go-out ul li {
    margin-bottom: 0;
  }
  .one-news-item__content__imgdesc {
    padding-bottom: 43%;
    margin-left: 0;
    margin-right: 0;
  }
  .about-page .content-width {
    padding: 0 8.5rem;
  }
  .about-page .about-item--1 .desc {
    padding-bottom: 4rem;
  }
  .about-page .about-item--1 .thumb.sharpen-imports {
    margin: 0 auto;
    padding-bottom: 70%;
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .about-page .about-item--2 .desc {
    padding-bottom: 4rem;
  }
  .about-page .about-item--2 .thumb.sharpen-imports {
    padding-bottom: 70%;
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .about-page .video-information {
    overflow: hidden;
    padding: 7% 0;
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .about-page .video-information__video-left {
    float: left;
    padding-left: 0;
    width: 8%;
    overflow: hidden;
  }
  .about-page .video-information__video-left img {
    margin-right: 1rem;
  }
  .about-page .video-information__video-main {
    float: left;
    width: 80%;
    overflow: hidden;
    margin-left: 2rem;
  }
  .about-page .video-information__video-main img {
    width: 100%;
    height: auto;
  }
  .about-page .video-information__video-right {
    float: right;
    width: 8%;
    overflow: hidden;
  }
  .about-page .content-reward {
    overflow: hidden;
  }
  .about-page .content-reward .certify-color {
    display: none;
  }
  .about-page .content-reward .certify {
    display: inline-block;
  }
  .about-page .reward-list {
    margin: 0 auto;
  }
  .about-page .reward-list .reward {
    display: none;
  }
  .about-page .reward-list .img-black {
    padding: 0;
    margin: 0 -0.5rem;
    display: block;
  }
  .about-page .reward-list .img-black .reward__item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .about-page .reward-list .img-black .reward__item img {
    width: 100%;
    height: 100%;
  }
  .about-page .reward-list .img-color {
    display: none;
  }
  .about-page .sm {
    display: none;
  }
  .about-page .tl {
    display: inline-block;
  }
  .block-list-store__title {
    text-align: left;
  }
  .list-tab-store {
    position: relative;
    float: right;
    top: -45px;
  }
  .private-label-page .read-more {
    margin-top: 8rem;
  }
  .private-label-page .introduce {
    overflow: hidden;
    position: relative;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .private-label-page .introduce__content .introduce-text {
    padding: 0 8.5rem;
    padding-bottom: 8rem;
  }
  .private-label-page .introduce--full-height {
    padding-top: 0;
    padding-bottom: 0;
  }
  .private-label-page .introduce--full-height .review-360 {
    padding-bottom: 70%;
    background-position: center;
    background-size: cover;
    text-align: center;
    display: block;
  }
  .private-label-page .introduce--full-height .review-360__text {
    color: #fff;
    position: absolute;
    transition: translateY(-50%);
    top: 40%;
    left: 0;
    width: 100%;
  }
  .private-label-page .introduce--full-height .review-360__text h2 {
    font-weight: 500;
    color: #fff;
    padding: 0 1.5rem;
  }
  .private-label-page .introduce--full-height .review-360__text img {
    margin-top: 3rem;
  }
  .private-label-page .introduce--full-height .review-360 a {
    color: #fff;
  }
  .private-label-page .introduce--full-height .review-360 a:hover {
    color: #fff;
  }
  .private-label-page .introduce .thumb.bg-ome {
    width: 80%;
    padding-bottom: 55%;
  }
  .private-label-page .introduce .thumb.quality {
    padding-bottom: 50%;
    margin: 0 auto;
    padding-top: 8rem;
    padding-right: 8.5rem;
    padding-left: 8.5rem;
  }
  .private-label-page .introduce .thumb.quality .title-heading {
    max-width: 117rem;
    margin: 0 auto;
  }
  .private-label-page .introduce .thumb.view-360 {
    background-size: cover;
    background-position: center center;
    padding-bottom: 75%;
    width: 100%;
  }
  .private-label-page .list {
    width: 100%;
    background-color: #fff;
  }
  .private-label-page .list-product {
    overflow: hidden;
    position: relative;
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    background-color: #fff;
  }
  .private-label-page .list-product__images {
    text-align: center;
    padding-right: 3rem;
    padding-left: 3rem;
    overflow: hidden;
  }
  .private-label-page .list-product__images img {
    height: auto;
    width: 100%;
    display: none;
  }
  .private-label-page .list-product__images--change img {
    max-height: 100rem;
  }
  .private-label-page .list-product__desc {
    padding-right: 8.5rem;
  }
  .private-label-page .list-product__desc .images-list {
    width: 90%;
    overflow: hidden;
    float: left;
    text-align: center;
    margin-left: 0;
  }
  .private-label-page .list-product__desc .images-list .active {
    border: 1px #71bf43 solid;
  }
  .private-label-page .list-product__desc .images-list .full-width {
    width: 50%;
    overflow: hidden;
    text-align: center;
    float: left;
    vertical-align: middle;
  }
  .private-label-page .list-product__desc .images-list .full-width .set-width {
    width: 100%;
    height: 14rem;
    background-color: #fff;
  }
  .private-label-page .list-product__desc .images-list .full-width img {
    width: 100%;
    height: 100%;
  }
  .private-label-page .list-product__desc .images-list .full-height {
    width: 50%;
    overflow: hidden;
    text-align: center;
    float: left;
    vertical-align: middle;
  }
  .private-label-page .list-product__desc .images-list .full-height .set-hieght {
    height: 20rem;
    width: 100%;
    padding: 1rem 0;
    background-color: #fff;
  }
  .private-label-page .list-product__desc .images-list .full-height img {
    height: 100%;
  }
  .private-label-page .list-product .desc-title {
    line-height: 2;
  }
  .private-label-page .list-product .images-num {
    display: none;
  }
  .private-label-page .private-product-list__header {
    display: none;
  }
  .private-label-page .private-product-list__container li {
    margin-bottom: 0;
  }
  .private-label-page .mennu-list-product {
    background-color: #fff;
  }
  .private-label-page .mennu-product {
    display: none;
  }
  .private-label-page .demand {
    background-color: #F7F9F8;
  }
  .private-label-page .demand__desc {
    line-height: 2;
    padding: 5rem 8.5rem;
  }
  .private-label-page .images-item img {
    height: 20rem;
  }
  .private-label-page .def {
    border: 1px #eeeeee solid;
  }
  .private-label-page .display-mb {
    display: none;
  }
  .private-label-page .display-tl {
    display: block;
  }
  .private-label-page .display-dk {
    display: none;
  }
  .private-label-page .display-tabl-desk {
    display: block;
  }
  .connect--change {
    max-width: none;
  }
  .connect__send-mail {
    padding: 0 8.5rem;
  }
  .connect__send-mail .main-title--1, .connect__send-mail > p {
    text-align: center;
  }
  .connect__address, .connect__form {
    padding: 0 7rem;
  }
  .connect__form .contact-form {
    margin-top: 4rem;
  }
  .connect .g-recaptcha {
    max-width: 61.5rem;
  }
  .wrapper-content {
    max-width: 117rem;
  }
  .connect__form .button--black {
    float: left;
  }
  .sidebar-right {
    padding-top: 8rem;
  }
  .faq .button--black, .recruit .button--black {
    width: auto;
  }
  .faq-content, .recruit-content {
    opacity: 1;
    transition: opacity 0.3s ease;
    padding-left: 15px;
    padding-right: 15px;
  }
  .faq-content.hidden, .recruit-content.hidden {
    opacity: 0;
    display: none;
  }
  .faq-content .block-position-detail, .recruit-content .block-position-detail {
    margin-bottom: 2rem;
  }
  .faq-content .block-position-detail .panel-heading, .recruit-content .block-position-detail .panel-heading {
    position: relative;
    border-right: 1px solid #efefef;
  }
  .faq-content .block-position-detail .panel-heading a, .recruit-content .block-position-detail .panel-heading a {
    line-height: 1.5;
  }
  .about-us-slider {
    width: 100%;
    height: 50vh;
    min-height: 40rem;
    position: relative;
    overflow: visible;
  }
  .about-us-slider li {
    position: absolute;
    width: 70%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: margin 1s cubic-bezier(0.19, 1, 0.22, 1), transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    border-radius: 5px;
    background-color: #000;
  }
  .about-us-slider li.about-us-slider__current {
    z-index: 2;
    transform: translate(-50%, -55%);
  }
  .about-us-slider li.about-us-slider__current .about-us-slider-overlay:before {
    opacity: 0;
    visibility: hidden;
  }
  .about-us-slider-overlay {
    display: block;
    position: absolute;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .about-us-slider-overlay:before {
    opacity: 1;
    visibility: visible;
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(49, 49, 49, 0.5);
    cursor: pointer;
  }
  .about-us-slider-overlay__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .contact-page .title {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .contact-page .contact-form {
    margin: 1rem 7rem 0;
  }
  .contact-page .set-mb-xs p {
    width: 90%;
  }
  .contact-form .two-half div:first-child {
    padding-right: 1rem;
  }
  .contact-form .two-half div:last-child {
    padding-left: 1rem;
  }
}
