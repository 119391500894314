.about-us-slider {
	width: 100%;
	height: 50vh;
	min-height: 40rem;
	position: relative;
	overflow: visible;

	li {
		position: absolute;
		width: 70%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: margin 1s cubic-bezier(0.19, 1, 0.22, 1), transform 1s cubic-bezier(0.19, 1, 0.22, 1);
		border-radius: 5px;
		background-color: $black;

		&.about-us-slider__current {
			z-index: 2;
			transform: translate(-50%, -55%);

			.about-us-slider-overlay:before {
				opacity: 0;
				visibility: hidden;
			}
		}

	}
}

.about-us-slider-overlay {
	display: block;
	position: absolute;
	background-size: cover;
	background-position: center;
	border-radius: 5px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&:before {
		opacity: 1;
		visibility: visible;
		content: ' ';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(49, 49, 49, 0.5);
		cursor: pointer;
	}

	&__play-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
