
// trademark-KN-page

.trademark-omi-page, .trademark-obeauty-page, .trademark-nghia-page, .trademark-export-page {
	.introduce {
		padding: 8rem 8.5rem;

		&__content {
			.introduce-text {
				padding-right: 2.5rem;
				line-height: 2;
				> p {
					color: $grey-9;
				}

			}
		}

	}

	.products {
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 8rem;
	}

	.export-item {
		.thumb {

			&.sharpen-imports {
				padding-bottom: 72%;
			}
		}
	}

	.export-item--1 .desc {
		padding: 8rem 8.5rem;
	}

	.tabs--trademark {

		.export-desc {
			text-align: center;
			padding: 8rem 13.5rem;

			.wrapper-img {
				display: inline-block;
				padding-bottom: 2rem;
			}

		}
	}

	.export-comment {

		.comment-customer {
			padding: 8rem 4rem;
			height: 50rem;
		}
	}

	.tabs-item--1 {
		.desc {
			p {
				height: 9rem;
			}
		}
	}

	.sm {
		display:none;
	}

	.tl {
		display:inline-block;
	}

	.dk {
		display:none;
	}
}

.tabs--trademark {
	.export-desc {

		p {
			text-align: center;
		}
	}
}
