.home-item {
	&--1 {
		.thumb, .desc {
			float: right;
		}

		// .sub-heading {
		// 	font-size: 2.6rem;
		// }
	}


	&--2 {
		height: 100vh;

		&__content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 80%;
		}
	}

	&--3 {
		height: 80rem;
		background-image: radial-gradient(circle, $white, $grey-light);

		.bgkem-item-3 {
			position: absolute;
			margin: 0;
			top: 60%;
			left: 35%;
			transform: translate(-50%, -50%);
			width: 45rem;
			max-width: none;
			padding-top: 45rem;

			&__shadow {
				position: absolute;
				top: 97%;
				left: 32%;
				width: 35rem;
				transform: translate(-50%, -50%);
			}
		}

		&__container {
			height: 100%;
			position: relative;
			max-width: $screen-md-min;
			margin: 0 auto;
		}
	}
}


.homepage {
	.video-information {
		display: block;
		overflow: hidden;
		padding: 7rem 0;
	}

	.history-us {

		&__content {
			.thumb {
				float: right;
			}
		}

		&__header2 {
			padding-top: 7rem;
			padding-bottom: 3rem;
		}

		&__image {
			padding-bottom: 30%;
		}

		&__text {
			padding-top: 0;
		}

	}
}

.cover-block-video {
	margin-top: 0;
	height: 60vh;
}

.connect__send-mail {
	.send-mail {
		max-width: 615px;
		margin: 0 auto;

		.form-group {
			width: 70%;
			float: left;
			text-align: left;
		}

		.button {
			width: 30%;
			float: right;

			&:hover, &:focus {
				box-shadow: none;
			}
		}
	}
}

.follow {
	&__social {
		text-align: left;
	}

	.main-title--1 {
		text-align: left;
	}
}

.connect__form {
	.main-title--1 {
		text-align: left;
	}
}

.list-logo-branch {
	margin: 3rem 0;

	ul {
		li {
			width: 25%;

			a {
				width: auto;
				text-align: left;

				img {
					max-width: 100%;
				}
			}
		}
	}
}

.time-list-xs {

	&--fixed {
		top: 8rem;
	}

	li {
		font-size: 1.2rem;
		width: 11%;

		&:last-child {
			width: 16%;
		}
	}
}

.list-tooltip-item {

	.size {
		top: 47%;
		left: 57%;
		transform: translate(-50%, -50%);
	}

	.material {
		top: 60%;
		left: 52%;
		transform: translate(-50%, -50%);
	}

	.reliability {
		top: 65%;
		left: 36%;
		transform: translate(-50%, -50%);
	}

	.designs {
		top: 80%;
		left: 44%;
		transform: translate(-50%, -50%);
	}

	&__description {
		position: absolute;
		top: 37%;
		left: 64%;
		transform: translate(-50%, -50%);
	}

	&__image {
		position: absolute;
		top: 13%;
		left: 12%;
		transform: translate(-50%, -50%);
	}

	.tooltip-item {

		&__image-container {
			position: absolute;
			border-radius: 100%;
			background-color: $white;
			top: 18rem;
			left: 18rem;
			width: 0;
			height: 0;
			overflow: hidden;

			img {
				margin: 0 auto;
				margin-top: -7rem;
				display: block;

				&.active {
					margin-top: 0;
				}
			}

			&.active {
				top: 0;
				left: 0;
				width: 36rem;
				height: 36rem;
			}
		}

		&__description {
			position: absolute;
			width: 25rem;
			line-height: 2;

			&__title {
				position: relative;
				text-transform: uppercase;
				font-weight: 600;
				visibility: hidden;
				opacity: 0;
				top: -2rem;
			}

			&__content {
				position: relative;
				visibility: hidden;
				opacity: 0;
				bottom: -2rem;
			}

			&.active {

				.tooltip-item__description__title {
					visibility: visible;
					opacity: 1;
					top: 0;
				}

				.tooltip-item__description__content {
					visibility: visible;
					opacity: 1;
					bottom: 0;
				}
			}

		}

	}
}
