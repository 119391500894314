.footer {

	p {
		font-size: 1.4rem;
	}

	.container {
		display: table;

		[class^="col"] {
			display: table-cell;
			vertical-align: middle;
			float: none;
		}
	}

	&__left {
		text-align: left;
		display: inline-block;
	}

	&__image {
		display: inline-block;
		margin-left: 2rem;
		iframe {
			width: 5rem !important;
			height: 4.3rem !important;
			margin: 0;
		}
	}

	&__right {
		text-align: right;
	}
}
