.contact-page {

	.title {
		padding-left: 8.5rem;
		padding-right: 8.5rem;
	}

	.contact-form {
		margin: 1rem 7rem 0;
	}

	.set-mb-xs {

		p {
			width: 90%;
		}
	}
}

.contact-form {
	.two-half {
		div {
			&:first-child {
				padding-right: 1rem;
			}

			&:last-child {
				padding-left: 1rem;
			}
		}
	}
}

