.header {
	height: 8rem;


	&__inner {
		height: 8rem;
		padding-left: 2.5rem;
	}

	&--light {
		.header__background {
			height: 8rem;
		}
	}
}

.navbar-toggle {
	height: 8rem;
	font-size: 3rem;
	right: 2.5rem;
}

.mennu-toggle {
	height: 8rem;
	font-size: 3rem;
	// right: 2.4rem;
	right: 2.5rem;
}

.logo {

	img {
		width: 11rem;
	}
}

.header__content {
 	padding-left: 0;
 	margin-left: 0;
}

.nav-mobile {
	.nav-mobile-inner {
		right: 0;
		left: inherit;
		width: 40rem;
	}

	.close {
		right: 40rem;
		left: inherit;
	}

	.has-dropdown  {
		.expand-dropdown {
			right: 0rem;
		}
	}
}
