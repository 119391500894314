.product-list-page {

	.wrapper-content {
		position: relative;
	}

	.main {
		padding: 0 1.5rem;
	}

	.product-list-wrapper {

		padding-top: 8rem;
		margin-bottom: 5.5rem;
		margin-left: 15rem;
		overflow: hidden;

	}

	.product-list {
		text-align: justify;
		margin-left: 4rem;
		margin-top: 5rem;
	}

	.product {
		vertical-align: bottom;
		border: none;
		display: inline-block;
		margin-bottom: 5rem;

		&__thumb {
			height: 20rem;
			display: block;
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		&__serial {
			font-size: 1.6rem;
			height: 5rem;

			> span {
				display: block;
			}
		}

		&__title {
			font-size: 1.6rem;
		}
	}

	&__footer {
		background-color: $white;
		height: 5.5rem;
		border-top: 1px solid $grey-1;

		a {
			line-height: 3.5rem;
			padding: 0 1rem;
			width: 14rem;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
		}
	}

	.product-list-filter-wrapper {
		position: fixed;
		top: 0;
		bottom: 0;
		z-index: 55;
	}

	.product-list-filter {
		position: absolute;
		top: 8rem;
		left: 18rem;
		width: 32.5rem;
		padding: 1rem 1.5rem 1.5rem;


		&__header {
			font-size: 4.4rem;
		}

		&__child {
			padding: 1.5rem 0;
		}

		&__footer {
			padding: 4rem 1.5rem;
			bottom: 8.1rem;
		}
	}

	@media (max-width: $screen-md-min - 1) {
		.product {
			&:nth-child(2n+1) {
				clear: left;
			}
		}
	}
}
