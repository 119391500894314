.block-list-store {
	&__title {
		text-align: left;
	}
}

.list-tab-store {
	position: relative;
	float: right;
	top: -45px;
}
