.product-landing-page {

	.main {
		padding: 0 1.5rem;
	}

	.product-type-list {
		padding-top: 8rem;
	}

	.product-type {
		border: none;

		&__title {
			font-size: 4.4rem;
		}
	}
}

.product-pages-header {
	top: 7.7rem;
	width: auto;

	&__top {
		width: 18rem;
		border: 1px solid $grey-8;
		padding-top: 0;
		left: 1px;

		> ul {
			border-right: 1px solid $grey-8;
			width: 18rem;
		}

		a {
			border: none;
		}

		li > a {
			padding: 0;
			&::before {
				display: none;
			}
		}

	}

	&__left {
		border-right: none;
		width: 18rem;

		&__filter-menu {
			font-size: 5rem;

			.icon--view {
				font-size: 3rem;
			}

		}
	}
}
