

.about-page {

	.content-width {
		padding: 0 8.5rem;
	}

	.about-item {

		&--1 {

			.desc {
				padding-bottom: 4rem;

			}
			.thumb {

				&.sharpen-imports {
					// width: 85%;
					margin: 0 auto;
					padding-bottom: 70%;
					margin-left: 8.5rem;
					margin-right: 8.5rem;
				}

			}

		}
		&--2 {

			.desc {
				// padding-right: 0;
				// padding-left: 0;
				padding-bottom: 4rem;
			}
			.thumb {

				&.sharpen-imports {
					padding-bottom: 70%;
					margin-left: 8.5rem;
					margin-right: 8.5rem;

				}

			}
		}
	}

	.video-information {
		overflow: hidden;
		padding: 7% 0;
		width: 100%;
		position:relative;
		display: inline-block;
		// float:left;

		&__video-left {
			float: left;
			padding-left: 0;
			width: 8%;
			overflow: hidden;
			img {
				margin-right: 1rem;
			}

		}

		&__video-main {
			float: left;
			width: 80%;
			overflow: hidden;
			margin-left: 2rem;

			img {
				width: 100%;
				height: auto;
			}

		}

		&__video-right {
			float: right;
			width: 8%;
			overflow: hidden;
		}
	}

	.content-reward {
		overflow:hidden;

		.certify-color {
			display: none;

		}
		.certify {
			display: inline-block;
		}


	}

	.reward-list {
		margin: 0 auto;

		.reward {
			display: none;
		}

		.img-black {
			padding: 0;
			margin: 0 -0.5rem;
			display: block;

			.reward__item {

				padding-left: 0.5rem;
				padding-right: 0.5rem;

				img {
					width: 100%;
					height: 100%;
				}

				// &:nth-child(3n) {
				// 	padding-right: 1rem;
				// }
			}
		}

		.img-color {
			display: none;
		}
	}



	.sm {
		display:none;
	}
	.tl {
		display:inline-block;
	}
}