$bg-list: #F7F9F8;
$border-cl: #eeeeee;

.private-label-page {

	.read-more {
		margin-top: 8rem;
	}

	.introduce {
		overflow: hidden;
		position: relative;
		padding-top: 8rem;
		padding-bottom: 8rem;
		&__content {
			// text-align: center;

			.introduce-text {
				padding: 0 8.5rem;
				padding-bottom: 8rem;
			}
		}
		&--full-height {
			padding-top: 0;
			padding-bottom: 0;

			.review-360 {
				padding-bottom: 70%;
				background-position: center;
				background-size: cover;
				text-align: center;
				display: block;

				&__text {
					color: $white;
					position: absolute;
					transition: translateY(-50%);
					top: 40%;
					left: 0;
					width: 100%;

					h2 {
						font-weight: 500;
						color: $white;
						padding: 0 1.5rem;
					}

					img {
						margin-top: 3rem;
					}
				}

				a {
					color: $white;

					&:hover {
						color: $white;
					}
				}
			}
		}

		.thumb {

			&.bg-ome {
				width: 80%;
				padding-bottom: 55%;
			}
			&.quality {
				padding-bottom: 50%;
				margin: 0 auto;
				padding-top: 8rem;
				padding-right: 8.5rem;
				padding-left: 8.5rem;
				.title-heading {
					// padding: 0 10%;
					max-width: 117rem;
					margin: 0 auto;
				}
			}
			&.view-360 {
				background-size: cover;
				background-position: center center;
				padding-bottom: 75%;
				width: 100%;
			}

		}
	}
	.list {
			width: 100%;
			background-color: $white;
		}
	.list-product {
		// width: 100%;
		overflow: hidden;
		position: relative;
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
		background-color: $white;
		&__images {

			text-align: center;
			padding-right: 3rem;
			padding-left: 3rem;
			overflow: hidden;

		img {
				height: auto;
				width: 100%;
				display: none;
			}

			&--change {

				img {
					max-height: 100rem;
					// width: auto;
				}
			}
		}
		&__desc {
			// width: 50%;
			padding-right: 8.5rem;

			.images-list {
				width: 90%;
				overflow: hidden;
				float: left;
				text-align: center;
				margin-left: 0;


				.active {
					border: 1px $green1 solid;
				}


				.full-width {
					width: 50%;
					overflow: hidden;
					text-align: center;
					float: left;
					vertical-align: middle;

					.set-width {
						width: 100%;
						height: 14rem;
						background-color: $white;
					}

					img {
						width: 100%;
						height: 100%;
					}
				}
				.full-height {
					width: 50%;
					overflow: hidden;
					text-align: center;
					float: left;
					vertical-align: middle;

					.set-hieght {
						height: 20rem;
						width: 100%;
						padding: 1rem 0;
						background-color: $white;
					}
					img {
						height: 100%;

					}
				}
			}
		}

		.desc-title {
			line-height: 2;
		}

		.images-num {
			display:none;
		}
	}
	.private-product-list__header {
		display: none;
	}
	.private-product-list__container {

		li {
			margin-bottom: 0;
		}
	}
	.mennu-list-product {
		background-color: $white;
	}


	.mennu-product {
		display: none;
	}
	.demand {
		background-color: $bg-list;
	}

	.demand__desc {
		line-height: 2;
		padding: 5rem 8.5rem;
	}

	.images-item {

		img {
			height: 20rem;
		}

	}

	.def {
		border: 1px $border-cl solid;
	}







	.display-mb {
		display: none;
	}
	.display-tl {
		display: block;
	}
	.display-dk {
		display: none;
	}
	.display-tabl-desk {
		display: block;
	}

}
