// Tablet layout
.section-cover {

	&__content {
		padding-left: 7rem;
		padding-right: 7rem;

		.button--trans {
			width: auto;
			margin: 0 auto;
		}
	}
}

.bg-cover-other-pr {
	// Background of recruit
	&--recruit-thumb {
		padding-bottom: 0%;
	}

	&--banner-top-static {
		padding-bottom: 20%;
	}
}

.my-container-tablet {
	width: 75rem;
	margin: 0 auto;
}
