.one-news-item {
	padding-top: 4rem;

	&:first-child {
		padding-top: 5rem;
	}

	&__publish-time {
		position: absolute;
		left: -8rem;
		width: 7rem;
		margin-top: 0;

		&__day {
			width: 100%;
			border-right: 0;
			border-bottom: 1px solid $grey19;
			padding: 1.5rem;
			margin: 0;

			li {
				font-size: 2rem;
				margin: 0;
				font-weight: 600;
				line-height: 1;

				&:first-child {
					font-weight: 700;
					font-size: 2.6rem;
				}
			}
		}

		&__icon {
			line-height: 1;
			padding-top: 1.5rem;
			padding-left: 0;
			font-size: 3rem;
			text-align: center;
			margin: 0 2rem;
		}
	}

	&__content {
		position: relative;
		float: right;

		&__short {
			margin-bottom: 4rem;
		}

		&__go-out {
			margin-bottom: 4rem;

			ul {
				li {
					margin-bottom: 0;
				}
			}
		}

		&__imgdesc {
			padding-bottom: 43%;
			margin-left: 0;
			margin-right: 0;
		}
	}
}
