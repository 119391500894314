/**
 * Style for phablets & tablets
 */
@import 'site/variables';
@import 'site/mixins';
@import 'site/grid-framework';
@import 'site/responsive-utilities';
@import 'site/component-animations';


@media (min-width: $screen-sm-min) {
	// Small grid
	//
	// Columns, offsets, pushes, and pulls for the small device range, from phones
	// to tablets.
	@include make-grid(sm);
	@import 'tablet/layout';
	@import 'tablet/header';
	@import 'tablet/home';
	@import 'tablet/footer';
	@import 'tablet/banner';
	@import 'tablet/trademark-export';
	@import 'tablet/trademark-KN';
	@import 'tablet/trademark-obeauty';
	@import 'tablet/trademark-OMI';
	@import 'tablet/product-detail';
	@import 'tablet/product-landing';
	@import 'tablet/product-list';
	@import 'tablet/news';
	@import 'tablet/about-us';
	@import 'tablet/store';
	@import 'tablet/private';
	@import 'tablet/block';
	@import 'tablet/faq';
	@import 'tablet/about-us-slider';
	@import 'tablet/news-detail';
	@import 'tablet/contact';
	@import 'tablet/responsive-embed';
	@import 'tablet/guideadmin';
}
